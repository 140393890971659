import React from 'react'
import styles from './index.module.css'
import Container from '../../Container'

import solution_Ad1 from './assets/Solution_Ad1.png'
import solution_Ad2 from './assets/Solution_Ad2.png'
import solution_Ad3 from './assets/Solution_Ad3.png'
import solution_Ad4 from './assets/Solution_Ad4.png'
import solution_Ad5 from './assets/Solution_Ad5.png'
import solution_Ad6 from './assets/Solution_Ad6.png'

import googleAd from './assets/logo-google-ad-manager.png'
import freewheel from './assets/freeWheel-white.png'

const Advertising = () => {
  return (
    <section className={styles.Advertising}>
      <div className={styles.images}>
        <Container>
          <h2 className={styles.title}>TV ADVERTISING</h2>
          <p className={styles.subtitle}>
            Advertising provides additional revenues for service providers
            looking for increasing ARPU. iFeelSmart has been a pionnier to
            support TV advertising like pre roll and mid roll ads for on demand
            video content. iFeelSmart operator tier launcher is also integrated
            with TV advertising systems like Google Ads Server to provide on
            screen advertising use cases like the ones following.
          </p>
          <div className={styles.wrapper}>
            <div className={styles.advertisements}>
              <figure className={styles.advertising}>
                <img src={solution_Ad1} alt="Boot screen ADS" />
                <figcaption>Boot screen ADS</figcaption>
              </figure>
              <figure className={styles.advertising}>
                <img src={solution_Ad2} alt="Pause ADS" />
                <figcaption>Pause ADS</figcaption>
              </figure>
            </div>
            <div className={styles.advertisements}>
              <figure className={styles.advertising}>
                <img src={solution_Ad3} alt="Banner ADS" />
                <figcaption>Banner ADS</figcaption>
              </figure>
              <figure className={styles.advertising}>
                <img src={solution_Ad4} alt="Thumbnail ADS" />
                <figcaption>Thumbnail ADS</figcaption>
              </figure>
            </div>
            <div className={styles.advertisements}>
              <figure className={styles.advertising}>
                <img src={solution_Ad5} alt="Pre-roll ADS" />
                <figcaption>Pre-roll ADS</figcaption>
              </figure>
              <figure className={styles.advertising}>
                <img src={solution_Ad6} alt="Pop-up ADS" />
                <figcaption>Pop-up ADS</figcaption>
              </figure>
            </div>
          </div>
          <div className={styles.logos}>
            <figure>
              <img src={googleAd} alt="google ad manager logo" />
            </figure>
            <figure className={styles.freewheel}>
              <img src={freewheel} alt="freewheel logo" />
            </figure>
          </div>
        </Container>
      </div>
      <div className={styles.content}>
        <div className={styles.gradient}></div>
        {/* <p>
          Advertising provides additional revenues for service providers looking
          for increasing ARPU. iFeelSmart has been a pionnier to support TV
          advertising like pre roll and mid roll ads for on demand video
          content. iFeelSmart operator tier launcher is also integrated with TV
          advertising systems like Google Ads Server to provide on screen
          advertising use cases like the ones above.
        </p> */}
      </div>
    </section>
  )
}

export default Advertising
