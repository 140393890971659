import React from 'react'
import styles from './index.module.css'
import Container from '../../Container'
import InfoBanner from '../../InfoBanner'
import Bubble from '../../Bubble'
import Remote from '../Remote'

import tv from './assets/tvGoogleAssistant.png'
import googleAssistant from './assets/Google_Assistant_logo.png'
import iconVoice from './assets/Solution_Icon_Voice.png'

const Assistant = () => {
  return (
    <>
      <InfoBanner rootStyle={styles.infoBanner}>
        <figure>
          <img src={googleAssistant} alt="Google Assistant logo" />
        </figure>
        <span>Google Assistant Natural TV Interaction</span>
      </InfoBanner>
      <section className={styles.Assistant}>
        <Container>
          <h2 className={styles.title}>Vocal Search & Command</h2>
          <p className={styles.subtitle}>
            iFeelSmart has already deployed on the market Google Assistant
            technology for vocal search but also for vocal TV command.
            iFeelSmart has made significant investments since several years on
            natural TV interaction like vocal and gesture control.
          </p>
          <div className={styles.commands}>
            <figure>
              <img src={iconVoice} alt="icon voice" />
            </figure>
            <Bubble top>« Ok Google, Zap channel 6 »</Bubble>
            <Bubble top>« Ok Google, What's on TV now »</Bubble>
          </div>
        </Container>
      </section>
      <section className={styles.cloudPlateform}>
        <Container>
          <h2 className={styles.title}>
            experience managed by service provider <br />
            with cloud to cloud platform
          </h2>
          <p className={styles.subtitle}>
            With Google Assistant Cloud to Cloud platform integration,
            iFeelSmart Operator Tier launcher offers to service providers some
            additional features and customization options within Assistant
            experience like UX customization and branding or prioritization of
            operator results.
          </p>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <figure className={styles.tv}>
              <img src={tv} alt="tv sample" />
            </figure>
            <Remote rootStyle={styles.remote} />
          </div>
        </Container>
      </section>
    </>
  )
}

export default Assistant
