import React from 'react'
import classnames from 'classnames'
import styles from './index.module.css'
import Container from '../../Container'
import InfoBanner from '../../InfoBanner'
import Hr from '../../Hr'

import emiLogo from './assets/Solution_Emi_Logo.png'
import emiImage from './assets/Solution_ImgEMI.png'

const Emi = () => {
  return (
    <>
      <section className={styles.Emi}>
        <Container>
          <h2 className={styles.title}>editorial management interface</h2>
          <Container rootStyle={styles.descriptions}>
            <div className={styles.description}>
              <h3>Control consumers intermediation</h3>
              <Hr rootStyle={styles.hr} />
              <p>
                With OTT and Android apps ecosystem growing, needs for
                controlling intermediation with consumers is strategic. With
                EMI, iFeelSmart provides the right tool to do so.
              </p>
            </div>
            <div className={styles.description}>
              <h3>Cloud base service</h3>
              <Hr rootStyle={styles.hr} />
              <p>
                EMI is a cloud based service for service provider or content
                aggregator marketing team to manage TV real estate. EMI solution
                proposes different modules related to TV application services
              </p>
            </div>
          </Container>
          <figure>
            <img src={emiImage} alt="EMI Interface" />
          </figure>
          <div className={styles.cards}>
            <div className={styles.card}>TV Real Estate Management</div>
            <div className={styles.card}>Business Revenues opportunities</div>
            <div className={styles.card}>3rd Parties Delegations</div>
            <div className={styles.card}>UX control</div>
            <div className={styles.card}>Flexible business rules</div>
            <div className={styles.card}>OTT Apps Deeplink Management</div>
          </div>
        </Container>
        <div className={classnames(styles.corner, styles.bottomRight)}></div>
      </section>
      <InfoBanner rootStyle={styles.infoBanner}>
        <figure>
          <img src={emiLogo} alt="logo Emi" />
        </figure>
        <span>Transform TV Audience in news revenue streams</span>
      </InfoBanner>
    </>
  )
}

export default Emi
