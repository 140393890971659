import React from 'react'
import styles from './index.module.css'
import Container from '../../Container'

import graphic from './assets/Solution_Graphic.png'
import nielsen from './assets/nielsen.svg'
import googleAnalytics from './assets/googleAnalytics.svg'
import comscore from './assets/comscore.svg'
import adobe from './assets/Adobe.svg'

const Analytic = () => {
  return (
    <>
      <section className={styles.Analytic}>
        <Container>
          <h2 className={styles.title}>BIG DATA</h2>
          <p className={styles.subtitle}>
            Understanding customers behaviour, tracking consumption and ads
            displays, and measuring curation performances are strategic
            activities to optimise your product and generate more revenues from
            advertising and cross-sales. iFeelSmart's products are already
            integrated with the major analytics dashboards and data lake
            solutions and are certified by industry leaders like Nielsen.
          </p>
          <Container rootStyle={styles.container}>
            <ul className={styles.advantages}>
              <li>Usages tracking</li>
              <li>Customer journey analysis</li>
              <li>Curation ROI</li>
              <li>Audience measurement</li>
            </ul>
            <figure>
              <img
                className={styles.graphic}
                src={graphic}
                alt="data graphics"
              />
            </figure>
          </Container>
          <div className={styles.logos}>
            <figure>
              <img src={nielsen} alt="Nielsen logo" />
            </figure>
            <figure>
              <img src={googleAnalytics} alt="Google Analytics logo" />
            </figure>
            <figure>
              <img src={comscore} alt="Comscore logo" />
            </figure>
            <figure>
              <img src={adobe} alt="Adobe logo" />
            </figure>
          </div>
        </Container>
      </section>
    </>
  )
}

export default Analytic
