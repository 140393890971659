import React from 'react'
import Layout from '../components/Layout'
import Analytic from '../components/SolutionsPage/Analytic'
import Emi from '../components/SolutionsPage/Emi'
import Assistant from '../components/SolutionsPage/Assistant'
import Aggregator from '../components/SolutionsPage/Aggregator'
import Advertising from '../components/SolutionsPage/Advertising'
import Remote from '../components/SolutionsPage/Remote'

export default () => (
  <Layout>
    <Aggregator />
    <Emi />
    <Advertising />
    <Analytic />
    <Assistant />
    <Remote />
  </Layout>
)
