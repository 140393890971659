import React from 'react'
import styles from './index.module.css'
import classnames from 'classnames'

import Bubble from '../../Bubble'

import remote from './assets/Solution_Remote.png'

const Remote = ({ rootStyle }) => {
  return (
    <div className={classnames(styles.Remote, rootStyle)}>
      <Bubble bottom>«&nbsp;Star&nbsp;Wars&nbsp;9&nbsp;»</Bubble>
      <figure>
        <img className={styles.remote} src={remote} alt="remote" />
      </figure>
    </div>
  )
}

export default Remote
